<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'result'"
              :total="dataTotal"
              :fetch-path="'tssManagement/fetch'"
              :filter-path="'tssManagement/filter'"
              :export-path="'tssManagement/export'"
              :actions="true"
              @selectedItems="saveSelectedItems"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <!-- <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" /> -->
                      <b-dropdown-item @click="showContact(field.user)">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> Contact information
                      </b-dropdown-item>
                      <b-dropdown-item
                        :href="$router.resolve({ name: field.route, params: { id: field.origenable_id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="researcher"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event, role: 'researcher' }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <p><strong>Date trip begin</strong></p>
                <div class="mb-1">
                  <label
                    for="start_date"
                    class="form-label"
                  >Start</label>
                  <date-picker
                    v-model="filters['start_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Start: ${$event}`, value: `${$event}` }, 'start_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="end_date"
                    class="form-label"
                  >End</label>
                  <date-picker
                    v-model="filters['start_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `End: ${$event}`, value: `${$event}` }, 'start_date_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >TSS</label>
                  <select
                    id=""
                    v-model="filters.tss"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Tss: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'tss')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Insurance</label>
                  <select
                    id=""
                    v-model="filters.insurance"
                    name=""
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Insurance: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'insurance')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Country</label>
                  <v-select
                    v-model="filters.country"
                    label="name"
                    :options="countries"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: 'Country: ' + ($event ? $event.name : ''), value: $event }, 'country')"
                  />
                </div>
              </template>
              <template #info-sheet-item>
                <div class="offcanvas-body offcanvas-body--view">

                  <h4 class="mb-2">
                    <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
                  </h4>

                  <ul
                    id="basic-list-group"
                    class="list-group"
                  >
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction1"
                              v-model="actions.checkInsurance"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction1"
                            >Check insurance</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction2"
                              v-model="actions.unCheckInsurance"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction2"
                            >Uncheck insurance</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction3"
                              v-model="actions.checkTss"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction3"
                            >Check TSS</label>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item draggable">
                      <div class="d-flex">
                        <div class="more-info">
                          <div class="form-check">
                            <input
                              id="tableAction4"
                              v-model="actions.unCheckTss"
                              type="checkbox"
                              class="form-check-input"
                            >
                            <label
                              class="form-check-label"
                              for="tableAction4"
                            >Uncheck TSS</label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    href="#"
                    title="apply actions"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="applyActions"
                  >Apply actions</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
      <ItemInfoSheet :table-id="contactInfoSheet">
        <template #info-sheet-title>
          View contact information
        </template>
        <template
          v-if="selectedContact"
          #info-sheet
        >
          <div class="offcanvas-body offcanvas-body--view">
            <div class="mb-1 offcanvas-body--view--title">
              <p>Name<br>
                <strong>{{ selectedContact.user_name }}</strong></p>
            </div>
            <div
              v-if="selectedContact.university"
              class="mb-1"
            >
              <p>University<br>
                <strong>{{ selectedContact.university.name }}</strong>
              </p>
            </div>
            <hr>
            <div
              v-if="selectedContact.department"
              class="mb-1"
            >
              <p>Department<br>
                <strong>{{ selectedContact.department.name }}</strong></p>
            </div>
            <div
              v-if="selectedContact.phone"
              class="mb-1"
            >
              <p>Phone<br>
                <strong>{{ selectedContact.phone }}</strong></p>
            </div>
            <div
              v-if="selectedContact.mobile_phone"
              class="mb-1"
            >
              <p>Mobile phone<br>
                <strong>{{ selectedContact.mobile_phone }}</strong></p>
            </div>
            <div
              v-if="selectedContact.email"
              class="mb-1"
            >
              <p>Email<br>
                <strong>{{ selectedContact.email }}</strong></p>
            </div>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import ItemInfoSheet from '../../partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
    ItemInfoSheet,
  },
  data() {
    return {
      selectedItems: [],
      actions: {
        checkInsurance: false,
        unCheckInsurance: false,
        checkTss: false,
        unCheckTss: false,
        archive: false,
        open: false,
      },
      selectedContact: null,
      contactInfoSheet: 1,
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'NIF/NIE',
          checked: true,
          order: 2,
        },
        {
          name: 'SSN',
          checked: true,
          order: 3,
        },
        {
          name: 'Date of birth',
          checked: true,
          order: 4,
        },
        {
          name: 'Type',
          checked: true,
          order: 5,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 6,
        },
        {
          name: 'End date',
          checked: true,
          order: 7,
        },
        {
          name: 'Country',
          checked: true,
          order: 8,
        },
        {
          name: 'TSS',
          checked: true,
          order: 9,
        },
        {
          name: 'Insurance',
          checked: true,
          order: 10,
        },
        {
          name: 'Address',
          checked: true,
          order: 11,
        },
        {
          name: 'Institution',
          checked: true,
          order: 12,
        },
      ],
      sortMapping: {
        Researcher: 'user.user_name',
        'NIF/NIE': 'user.nif',
        SSN: 'user.social_security_number',
        'Date of birth': 'user.birthday',
        Type: 'type',
        'Begin date': 'begin_date',
        'End date': 'end_date',
        Country: 'country',
        TSS: 'tss',
        Insurance: 'insurance',
        Address: 'address',
        Institution: 'place',
      },
      title: 'TSS Management',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'tssManagement/items',
      dataTotal: 'tssManagement/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      countries: 'citizenships/items',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'tss_management')
    await this.$store.dispatch('tssManagement/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'tss_management',
      })
    }
    this.$store.dispatch('citizenships/filter', this.$store.getters['filters/filters'])
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    showContact(contact) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.contactInfoSheet)
      this.selectedContact = contact
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('tssManagement/applyActions', { actions: this.actions, itemIds }).then(() => {
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
              this.$toastr.success('', 'Actions applied successfully!')
              this.$store.dispatch('tssManagement/filter', this.filters)
              this.actions = {
                checkInsurance: false,
                unCheckInsurance: false,
                checkTss: false,
                unCheckTss: false,
                archive: false,
                open: false,
              }
            })
          }
        })
      } else {
        Vue.swal('No items selected', '', 'warning')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.checkInsurance) {
        str += '<p class="text-success">Check insurance</p>'
      }

      if (this.actions.unCheckInsurance) {
        str += '<p class="text-success">Uncheck insurance</p>'
      }

      if (this.actions.checkTss) {
        str += '<p class="text-success">Check TSS</p>'
      }

      if (this.actions.unCheckTss) {
        str += '<p class="text-success">Uncheck TSS</p>'
      }

      return str
    },
  },
}
</script>
